import React, { useState, ReactElement, CSSProperties, useMemo } from "react";
import styles from "./style.module.scss";
import { getDomainForm } from "src/api";
import { useDomainContext } from "@hooks/useDomainContext";
import Form from "@components/shared/form";
import ProgressBar from "@components/shared/form/progressBar";
import CreditRepair from "./icons/creditRepair";
import { Colors } from "@lib/shared/types";
import map from "./staticContentMap";
import Caret from "./icons/caret";
import { FormProps } from "../form/formOld";
export default function CombinedForm({
    primaryColor,
    formClasses,
    mainBannerClassName,
    colors,
    backButtonClassName,
    title,
    description,
    blockClassName,
    blockContainerClassName,
    mainTitleClassName,
    descriptionClassName,
    secondaryTitleClassName,
    secondaryDescriptionClassName,
    question,
    showArrows,
    resetLeadId,
}: {
    primaryColor?: string;
    formClasses: FormProps["classes"];
    mainBannerClassName?: string;
    backButtonClassName?: string;
    colors?: Colors;
    title?: string;
    description?: string;
    blockClassName?: string;
    blockContainerClassName?: string;
    mainTitleClassName?: string;
    descriptionClassName?: string;
    secondaryTitleClassName?: string;
    secondaryDescriptionClassName?: string;
    question?: string;
    showArrows?: boolean;
    resetLeadId?: boolean;
}): ReactElement {
    const { form, updateForm, formattedTitle, domain } = useDomainContext();
    const categories = domain?.categories;
    const [isFormFetched, updateFormFetched] = useState(false);
    const [currentStep, setCurrentStep] = useState(0);
    const [formError, updateFetchFormError] = useState(null);
    const getForm = async (categorySlug: string) => {
        const { data, error } = await getDomainForm({
            categorySlug,
        });
        if (error) {
            // @ts-ignore
            updateFetchFormError(error);
        }
        if (data) {
            updateFetchFormError(null);
            updateForm(data);
            updateFormFetched(true); // display form component
            setCurrentStep(1); // move next step
        }
    };

    const formContent = useMemo(
        () =>
            isFormFetched ? (
                <React.Fragment key={form?.title}>
                    <Form
                        classes={formClasses}
                        colors={colors ?? {}}
                        showProgress={true}
                        progressShift={1}
                        backButton={{
                            class: `${styles["back-button"]} ${
                                backButtonClassName ?? ""
                            }`,
                            // label: "Back",
                            firstStepAction: () => {
                                setCurrentStep(0);
                            },
                        }}
                        preventWindowClose
                        resetLeadId={resetLeadId}
                        stepsConfig={[
                            {
                                stepOrder: 0,
                                hideTitle: false,
                                hideDescription: false,
                                forceStepTitle: true,
                            },
                        ]}
                    />
                </React.Fragment>
            ) : null,
        [isFormFetched, form],
    );

    return (
        <div className={mainBannerClassName}>
            <div className={`${styles.mainBannerWrapper}`}>
                <div className={`${styles.formWrapper}`}>
                    <div className={`${styles.formStepsWrapper}`}>
                        <div
                            style={{
                                transform: `translate(${
                                    currentStep * -100
                                }%, 0)`,
                            }}
                            className={`${styles.formStepsWrapperWindow}`}
                        >
                            <div className={`${styles.stepBlock}`}>
                                {currentStep === 0 && (
                                    <>
                                        <div>
                                            <h1
                                                className={`${
                                                    styles["outsideBrief"]
                                                } ${
                                                    mainTitleClassName
                                                        ? mainTitleClassName
                                                        : "text-base md:text-2xl"
                                                } font-bold  px-3.5 md:px-0 mx-auto mb-6  text-center`}
                                                dangerouslySetInnerHTML={{
                                                    __html:
                                                        formattedTitle ??
                                                        title ??
                                                        "",
                                                }}
                                            ></h1>
                                            <h2
                                                className={`${styles["outsideBrief"]} font-bold  px-3.5 md:px-0 mx-auto mb-6 text-base md:text-2xl text-center`}
                                            >
                                                {question}
                                            </h2>
                                            <p
                                                className={`${
                                                    styles.outsideTitle
                                                } ${
                                                    descriptionClassName
                                                        ? descriptionClassName
                                                        : "max-w-xl"
                                                }  mx-auto  md:px-0 text-center mb-4`}
                                            >
                                                {description}
                                            </p>
                                        </div>
                                        {formError && (
                                            <div
                                                className={styles.errorMessage}
                                            >
                                                Something went wrong
                                            </div>
                                        )}

                                        <div
                                            className={`${
                                                styles.serviceBlocks
                                            } flex text-lg mx-auto ${
                                                blockContainerClassName ?? ""
                                            }`}
                                            style={
                                                {
                                                    "--primary-color":
                                                        primaryColor,
                                                } as CSSProperties
                                            }
                                        >
                                            {categories?.map((item) => {
                                                return (
                                                    <label
                                                        key={item.slug}
                                                        className={`flex flex-col items-center justify-center text-center  rounded-lg mb-4  mx-2 ${
                                                            blockClassName ?? ""
                                                        }`}
                                                        onClick={() =>
                                                            void getForm(
                                                                item.slug,
                                                            )
                                                        }
                                                    >
                                                        <div className="mb-3">
                                                            {map[item.slug]
                                                                ?.icon ?? (
                                                                <CreditRepair />
                                                            )}
                                                        </div>

                                                        <div>
                                                            <span
                                                                className={`${
                                                                    secondaryTitleClassName
                                                                        ? secondaryTitleClassName
                                                                        : "text-base"
                                                                }   block  w-full`}
                                                            >
                                                                {map[item.slug]
                                                                    ?.label ??
                                                                    item.label ??
                                                                    item.name}
                                                            </span>
                                                            {map[item.slug]
                                                                ?.description && (
                                                                <div
                                                                    className={`${
                                                                        secondaryDescriptionClassName
                                                                            ? secondaryDescriptionClassName
                                                                            : ""
                                                                    } relative flex`}
                                                                >
                                                                    {
                                                                        map[
                                                                            item
                                                                                .slug
                                                                        ]
                                                                            ?.description
                                                                    }
                                                                </div>
                                                            )}
                                                        </div>
                                                        {showArrows && (
                                                            <div
                                                                className={`${styles.caret} absolute md:relative bottom-2 right-1 w-5 md:w-10 mt-4`}
                                                            >
                                                                <Caret />
                                                            </div>
                                                        )}
                                                    </label>
                                                );
                                            })}
                                        </div>
                                        <ProgressBar
                                            className={`${styles["progress-bar"]} ${styles["custom-progress"]}`}
                                            current={0}
                                            total={8}
                                            color={colors?.primaryColor}
                                        />
                                    </>
                                )}
                            </div>

                            <div className={`${styles.stepBlock}`}>
                                {currentStep === 1 && formContent}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
